import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Outlet } from 'react-router-dom';

import './App.css';
import PageWrapper from './components/PageWrapper.js';
import LandingPage from './pages/LandingPage';
import { ethers } from 'ethers';
import { WalletProvider } from './WalletContext'; // Adjust the path as needed


function getLibrary(provider) {
  return new ethers.providers.Web3Provider(provider);
}
const Layout = () => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/';


};

function App() {
  return (
    <WalletProvider>

    <Router>
      <PageWrapper>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* Wrap the Layout component around the routes that should have Navbar and Sidebar */}
          <Route element={<Layout />}>
            {/* Define nested routes here */}



            {/* Add more nested routes as needed */}
          </Route>
        </Routes>
      </PageWrapper>
    </Router>
    </WalletProvider>
    
  );
}

export default App;
