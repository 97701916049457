import React, { useState } from 'react';
import './GallerySlideshow.css'; // Make sure to update the CSS file with the new styles

const GallerySlideshow = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = ['/2.webp', '/6.webp', '/7.webp', '/8.webp', '/10.webp', '/11.webp', '/12.webp', '/13.webp'];

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="slideshow-container">
      <img src={images[currentImageIndex]} alt={`Gallery ${currentImageIndex + 1}`} className="gallery-image" />
      <button onClick={goToPrevImage} className="arrow left-arrow">&#10094;</button>
      <button onClick={goToNextImage} className="arrow right-arrow">&#10095;</button>
    </div>
  );
};

export default GallerySlideshow;
