import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'; 
import './css/LandingPage.css'; // Make sure to create this CSS file
import styled from 'styled-components';
import { BorderRad, Colors, Fonts, Transitions } from '../styles/global/styles';
import './css/Page2.css';
import { FaDiscord, FaTelegramPlane, FaShareAlt, FaTwitter, FaFacebook, FaInstagram, FaPhone, FaEnvelope} from 'react-icons/fa'; // Import icons from react-icons
import GallerySlideshow from '../components/GallerySlideshow';

const LandingPage = () => {


    const [showTitle, setShowTitle] = useState(false);
    const [showTechnical, setShowTechnical] = useState(false); // State for technical description visibility
  




    const toggleTechnicalDescription = () => {
        console.log("Toggling technical description"); // Debugging line
        setShowTechnical(!showTechnical); // Toggle the visibility
    };

    
    useEffect(() => {
      setShowTitle(true); // This triggers the fade-in effect on component mount
    }, []);


    


    const sectionsRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                    } else {
                        entry.target.classList.remove('visible');
                    }
                });
            },
            { threshold: 0.5 }
        );

        sectionsRef.current.forEach((section) => observer.observe(section));

        return () => sectionsRef.current.forEach((section) => observer.unobserve(section));
    }, []);

    const addToRefs = (el) => {
        if (el && !sectionsRef.current.includes(el)) {
            sectionsRef.current.push(el);
        }
    };

    return (
        <LandingWrapper>
        <div className="landing-page">
        <div className="hero-section">

        <div className="top-section">
                    <SocialLinksTop>
                        <SocialButtonTop href="https://facebook.com" target="_blank"><FaFacebook size="2rem"/> </SocialButtonTop>
                        <SocialButtonTop href="https://instagram.com/" target="_blank"><FaInstagram size="2rem"/> </SocialButtonTop>
                        <SocialButtonTop href="tel:+1-414-617-1794"><FaPhone size="2rem" /> </SocialButtonTop>

                        <SocialButtonTop href="mailto:email@bellastone.com">  <FaEnvelope size="2rem" />  </SocialButtonTop>
                    </SocialLinksTop>
                </div>
          <div className="moon-animation">
            <img 
                 src="/Logo.png" 
                 alt="Logo" 
                 style={{
                 marginTop: '-15%',
                 marginLeft: '15%', 
                 borderRadius: '15px',
                 width: '70%', // Increase width as needed
                 height: 'auto' // Maintain aspect ratio
                  }}
                 />
                </div>

        <div className={`title-button-container`}>
         
            
           {/** <EnterAppButton to="/AvaxMoons">Enter App</EnterAppButton>*/} 
          </div>

   
        </div>

        

            <div className="roadmap-container" style={{ backgroundColor: '#212121' }}>


                {/* The Genesis of Moons */}
                <section className="roadmap-entry-centered" ref={addToRefs}>
                <div className="roadmap-graphic" style={{ backgroundImage: "url('9.webp')" }}></div>
                <p>
      At Bella Stone, we blend design creativity with precision in every stone countertop project, making the installation process both enjoyable for homeowners and straightforward for contractors. Our services include design consulting, prompt lead times, laser measuring, and CNC fabrication. Located in Fredonia, we offer a wide range of materials, from natural stones to popular quartz surfaces like Cambria and Silestone. Experience personalized service for your residential remodels or commercial projects with Bella Stone, where innovation meets quality.
    </p>

                </section>

                <section className="roadmap-entry-contact" ref={addToRefs}>
                <ContactGrid>
  <ContactButton  href="tel:+1-414-617-1794">
    <FaPhone size="3rem" />
  </ContactButton>
  <ContactButton href="mailto:email@bellastone.com">
    <FaEnvelope size="3rem" />
  </ContactButton>
  <ContactButton href="https://www.instagram.com/YourInstagramPage" target="_blank">
    <FaInstagram size="3rem" />
  </ContactButton>
  <ContactButton href="https://www.facebook.com/YourFacebookPage" target="_blank">
    <FaFacebook size="3rem" />
  </ContactButton>
</ContactGrid>

                </section>

<section className="roadmap-entry" ref={addToRefs}>
  <div className="roadmap-graphic" style={{ backgroundImage: "url('3.webp')" }}></div>
  <ul className="services-list">
    <li>
      <strong>Design Consulting:</strong> Collaborate with our experts to find the perfect stone solution that aligns with your vision and space requirements.
    </li>
    <li>
      <strong>Prompt Lead Times:</strong> We value your time. Enjoy swift and efficient project timelines from initial consultation to final installation.
    </li>
    <li>
      <strong>Laser Measuring:</strong> Precision is key in our work. Our state-of-the-art laser measuring technology ensures a perfect fit for your countertops.
    </li>
    <li>
      <strong>CNC Fabrication:</strong> Leveraging advanced CNC machinery, we guarantee intricate designs and superior finish quality for every countertop.
    </li>
    <li>
      <strong>Material Selection:</strong> Choose from an extensive array of natural stones and renowned quartz surfaces to match your style and needs.
    </li>
  </ul>
</section>


<div className="gallery-slideshow-container">
  <GallerySlideshow />
</div>


         

            

             
{/** <section className="roadmap-entry" ref={addToRefs}>
  <div className="roadmap-graphic" style={{ backgroundImage: "url('3.webp')" }}></div>
  <ul className="services-list">
    <li>
      <strong>Design Consulting:</strong> Collaborate with our experts to find the perfect stone solution that aligns with your vision and space requirements.
    </li>
    <li>
      <strong>Prompt Lead Times:</strong> We value your time. Enjoy swift and efficient project timelines from initial consultation to final installation.
    </li>
    <li>
      <strong>Laser Measuring:</strong> Precision is key in our work. Our state-of-the-art laser measuring technology ensures a perfect fit for your countertops.
    </li>
    <li>
      <strong>CNC Fabrication:</strong> Leveraging advanced CNC machinery, we guarantee intricate designs and superior finish quality for every countertop.
    </li>
    <li>
      <strong>Material Selection:</strong> Choose from an extensive array of natural stones and renowned quartz surfaces to match your style and needs.
    </li>
  </ul>
</section>*/}

             



            </div>

       <Footer>
  <FooterContent>
    <MapsButton href="https://www.google.com/maps/place/Bella+Stone,+LLc/@43.4641412,-87.9532781,14.4z/data=!4m6!3m5!1s0x8804ecb6d0b3058f:0x75c35b6057b78256!8m2!3d43.4583816!4d-87.948204!16s%2Fg%2F1vbnpzy2?entry=ttu" target="_blank">
      <img src="/BellaMap.png" alt="Location" style={{ width: '250px', height: '250px', borderRadius: '7px' }} />
    </MapsButton>
  
   
                    <ContactGridFooter>
                        <SocialButtonTop href="https://facebook.com" target="_blank"><FaFacebook size="2rem"/> </SocialButtonTop>
                        <SocialButtonTop href="https://instagram.com/" target="_blank"><FaInstagram size="2rem"/> </SocialButtonTop>
                        <SocialButtonTop href="tel:+1-414-617-1794"><FaPhone size="2rem" /> </SocialButtonTop>

                        <SocialButtonTop href="mailto:email@bellastone.com">  <FaEnvelope size="2rem" />  </SocialButtonTop>
                    </ContactGridFooter>
  
  </FooterContent>
</Footer>

        </div>
        </LandingWrapper>
    );
};

export default LandingPage;


const LandingWrapper = styled.div`
margin-top: -100px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -20px; // Adjust as needed
  margin-bottom: 60px;
`;

export const EnterAppButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px; // Increased width
  height: 80px; // Increased height
  font-size: 1.8rem; // Increased font size
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: background-color 2.0s, color 1.0s, border-color 0.3s; /* Transition multiple properties */


  margin-top: 20px; // Adjust as needed
  text-decoration: none; // Remove underline from link

  &:hover, &:focus {
    background-color: #EADAB6;
    color: #000000;
    border: 2px solid #FFBFA9;
    border-radius: ${BorderRad.m};
  }
`;


export const TechButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%; // Increased width
  height: 80px; // Increased height
  font-size: 0.8rem; // Increased font size
  line-height: 1.5;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: ${Colors.White};
  border: 2px solid ${Colors.White};
  border-radius: ${BorderRad.m};
  background-color: #000000;
  cursor: pointer;
  transition: background-color 2.0s, color 1.0s, border-color 0.3s; /* Transition multiple properties */


  margin-top: 20px; // Adjust as needed
  text-decoration: none; // Remove underline from link

  &:hover, &:focus {
    background-color: #91edd9ff;
    color: #000000;
    border: 2px solid #FFBFA9;
    border-radius: ${BorderRad.m};
  }
`;



const TitleBox = styled.div`
  color: white;
  text-align: center;
  margin-top: -60px;

  h1 {
    font-size: 4rem; /* Increase the font size as needed */
    margin: 30; /* Optional: Adjust the margin to control spacing */
  }

  @media (max-width: 768px) {
    display:none;
  }
`;

const Slogan = styled.h2`
  color: #D4AF37; // This is a gold color
  text-align: center;
  font-weight: bold;
  margin-top: 20px; // Adjust as needed for spacing
  font-size: 2rem; // Adjust size as needed
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25); // Optional: Adds a subtle shadow for better readability
`;


const SocialButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  padding: 20px;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #333;
  }
`;


const SocialLinksTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
  padding-top: 20px; // Adjust this value as needed for spacing from the top
  @media (max-width: 768px) {
    margin-top: 120%;
  }
`;

const SocialButtonTop = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #EADAB6;
  border-radius: 40px;
  color: #000;
  text-decoration: none;

  &:hover {
    background-color: #FFBFA9;
  }
`;


const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20px; // Adjust this value as needed for spacing from the top
`;


const BottomSection = styled.div`
    position: absolute;
    bottom: 20px; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
`;

const ContractAddress = styled.p`
margin-top: 20px;
color:  #555;
font-size: 0.8rem; // Increased font size

`;



export const BuyButton = styled(Link)`

display: inline-flex;
align-items: center;
justify-content: center;
margin: 0 10px;
padding: 10px;
border-radius: 5px;
background-color: #000;
color: #fff;
text-decoration: none;
transition: background-color 0.9s;

&:hover {
  background-color: #333;
}
`;

const Footer = styled.footer`
  background-color: #333;
  padding: 20px 0;
  color: #fff;
`;



const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: px;
`;

const FooterButton = styled(EnterAppButton)`
  margin-top: 20px;
`;


const MapsButton = styled.a`
  align-self: flex-start; // Aligns the button to the left
  margin-right: auto; // Pushes everything else to the right
  margin-left: 30px;
  img {
    border-radius: 7px; // Adds a 7px border radius to the map image
  }

  @media (max-width: 768px) {
    align-self: center; // Center the button on smaller screens
    margin: 0 0 20px 0; // Add some margin to the bottom
  }
`;



const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PhoneNumber = styled.p`
  color: #fff;
  font-size: 1.5rem; // Adjust as needed
  margin: 10px 0; // Adds some space around the phone number
`;

const EmailButton = styled.a`
  display: inline-block;
  background-color: #EADAB6; // A gold-like color, adjust as needed
  color: #000;
  padding: 10px 20px;
  border-radius: ${BorderRad.m};
  text-decoration: none;
  font-size: 1.2rem; // Adjust as needed
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 30px;
  margin-right: 30px;

  &:hover, &:focus {
    background-color: #FFBFA9; // Adjust hover color as needed
    color: #fff;

  }

  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%; // Ensure it takes the full width of the footer
  padding: 0 20px; // Add some padding on the sides

  @media (max-width: 768px) {
    flex-direction: column; // Stack items vertically on smaller screens
  }
`;


const QuickLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 20px;

  a {
    color: #fff;
    text-decoration: none;
    margin: 5px 0;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const BusinessHours = styled.div`
  margin: 0 20px;
  color: #fff;

  h3 {
    margin-bottom: 10px;
  }
`;



const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 80px;
  justify-content: space-between;
  padding: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    gap: 20px;

  }
`;

const ContactGridFooter = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  justify-content: space-between;
  padding: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    gap: 20px;

  }
`;

const ContactButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: #EADAB6;
  border-radius: 40px;
  color: #000;
  text-decoration: none;

  &:hover {
    background-color: #FFBFA9;
  }
`;


